<template lang="pug">
div
  about-section-plans
  about-footer
</template>

<script>
import { mapState } from 'vuex'
import AboutSectionPlans from '@/components/AboutSectionPlans'
import AboutFooter from '@/components/AboutFooter'

export default {
  name: 'PlansView',
  components: {
    AboutSectionPlans,
    AboutFooter
  },
  computed: mapState('app', ['appTitle']),
  metaInfo: {
    title: 'Plans',
    meta: [
      {
        name: 'description',
        content: `nutworth plans and pricing. Try us for free or choose one of our simple, affordable plans.`
      }
    ]
  }
}
</script>

<style lang="sass" scoped></style>
