<template lang="pug">
  section.container.section
    .columns.top-offset-half#pricing
      .column.is-8.is-offset-2.has-text-centered
        h2.title Get started for free
        p.subtitle
          | We offer a generous free plan. For additional storage and sharing
          | features, you can choose to upgrade to our pro plan.
    .columns
      .column.is-12
        b-tabs(type='is-toggle' position='is-centered' v-model='tab')
          b-tab-item(label="USD")
          b-tab-item(label="AUD")
        .pricing-table.is-comparative
          .pricing-plan(v-for="plan in PlanInfo" :key='plan.id' :class='plan.classes')
            .plan-header {{plan.name}}
            .plan-price
              span.plan-price-amount
                span.plan-price-currency(v-if="plan.currency") {{ plan.currency }}
                | {{plan.price[tab]}}
              span(v-if="plan.period") /{{plan.period}}
            .plan-items
              .plan-item(v-for="line in plan.lines") {{ line }}
            .plan-footer
              router-link.button.is-fullwidth(:to="{name: 'sign_up', query: getQuery(plan)}")
                strong {{ plan.buttonText }}
    .columns.top-offset
      .column.is-8.is-offset-2.has-text-centered
        h2.title It's easy to get started.
        router-link.button.is-primary.is-large(:to="{name: 'sign_up'}")
          strong Create your free account
    .columns.top-offset
      .column.has-text-centered
        img.footer-logo(alt="nutworth logo" src="@/assets/img/logo.png")
</template>

<script>
import PlanInfo from '@/misc/plan-info-updated.js'

export default {
  data() {
    return {
      tab: navigator.language === 'en-AU' ? 1 : 0,
      PlanInfo
    }
  },
  methods: {
    getQuery(plan) {
      return {
        plan: plan.id,
        currency: ['usd', 'aud'][this.tab]
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.footer-logo
  height: 8rem
.top-offset
  margin-top: 5rem
.top-offset-half
  margin-top: 2.5rem
</style>
